<template>
  <el-table
    class="do-table-analytics"
    :data="rows"
    :row-key="rowKey"
    default-expand-all
    :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
    size="small"
    :show-summary="true"
    sum-text="Total"
    :table-layout="getTableLoyout()"
    :summary-method="isNeedCustomSum ? getSummaries : null"
  >
    <el-table-column :prop="title" :label="title">
      <template #header="scope">
        <div class="analytics-title-cell">
          <span
            v-if="!isOpenModal"
            class="analytics-title-button"
            @click="maximizeTable"
            v-html="Maximize"
          ></span>
          <div>
            {{ scope.column.label }}
          </div>

          <el-popover
            v-if="filtersMetaData.length"
            :visible="visibleFilters"
            placement="bottom"
            trigger="hover"
            :width="260"
          >
            <div class="analytics-filters-wrapper">
              <template v-for="(filter, idx) in filtersMetaData" :key="idx">
                <div
                  v-if="filter.key === 'date_month'"
                  class="analytics-filters-title"
                  :class="{ active: isVisibleAdvancedPeriod }"
                  @click="
                    setVisibilityAdvancedPeriod([
                      'date_month',
                      'date_year',
                      'link_builder_f',
                    ])
                  "
                >
                  Advanced settings
                </div>
                <div
                  class="analytics-filters-row"
                  v-if="!hideFilters.includes(filter.key)"
                >
                  <span>{{ filter.label }}</span>
                  <el-segmented
                    v-if="filter.type === 'segmented'"
                    v-model="filtersValue[filter.key]"
                    :options="filter.values"
                    size="small"
                    class="custom-style"
                    @change="handler(filter)"
                  />

                  <el-switch
                    v-else-if="filter.type === 'switch'"
                    v-model="filtersValue[filter.key]"
                    class="ml-2"
                    style="--el-switch-on-color: #fe8966"
                    size="small"
                    @change="handler(filter)"
                  />

                  <el-date-picker
                    v-else-if="['month', 'year'].includes(filter.type)"
                    v-model="filtersValue[filter.key]"
                    :type="filter.type"
                    size="small"
                    :clearable="false"
                    :value-format="'YYYY-MM-DD'"
                    @change="handler(filter)"
                    popper-class="analytics-filters-popover"
                  />

                  <el-select-v2
                    class="analytics-filters-select"
                    v-else-if="filter.type === 'select'"
                    v-model="filtersValue[filter.key]"
                    :options="filter.values"
                    multiple
                    size="small"
                    filterable
                    placeholder=""
                    collapse-tags
                    collapse-tags-tooltip
                    @change="handler(filter)"
                  />
                </div>
              </template>
            </div>

            <template #reference>
              <div
                class="analytics-title-cell-button"
                @click="visibleFilters = !visibleFilters"
              >
                Filters
              </div>
            </template>
          </el-popover>
        </div>
      </template>

      <el-table-column
        v-for="(column, index) in columns"
        :key="column.key"
        :prop="column.key"
        :label="column.text"
        :width="getColumnWidth(column, index)"
        :min-width="60"
        :fixed="!index"
        :align="!index ? 'left' : 'right'"
        :show-overflow-tooltip="!index ? true : false"
      >
        <template #default="scope">
          {{ column.textFormated(tableKey, column.key, scope.row[column.key]) }}
        </template>
      </el-table-column>
    </el-table-column>
  </el-table>
</template>

<script>
import { Maximize } from '@/utils/icons'

export default {
  name: 'AnalyticsTable',
  emits: ['update:modelValue', 'updateTable', 'maximize'],
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    tableParams: {
      type: Object,
      required: true,
    },
    filtersMetaDataProp: {
      type: Array,
      required: true,
    },
    tableKey: {
      type: String,
      required: true,
    },

    isMaximized: {
      type: Boolean,
      default: false,
    },

    isOpenModal: {
      type: Boolean,
      default: false,
    },
    getSummaries: {
      type: Function,
      default: () => {},
    },

    isNeedCustomSum: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      visibleFilters: false,
      titleEndPart: '',
      Maximize,
      isVisibleAdvancedPeriod: false,
      hideFilters: ['date_month', 'date_year', 'link_builder_f'],
    }
  },

  computed: {
    filtersValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },

    title() {
      return `${this.tableParams.title || ''} ${
        this.titleEndPart ? '[' + this.titleEndPart + ']' : ''
      }`
    },

    rowKey() {
      return this.tableParams.rowKey || 'name'
    },

    columns() {
      return this.tableParams.columns || []
    },

    rows() {
      return this.tableParams.rows || []
    },

    filtersMetaData() {
      return this.filtersMetaDataProp || []
    },
  },

  mounted() {
    this.updateTitleEndPart()
  },

  methods: {
    handler(filter) {
      for (const key in this.filtersValue) {
        if (key === 'details_f') continue
        else if (filter.key === 'date_month') {
          this.filtersValue['date_year'] = null
          this.filtersValue['date_f'] = null
        } else if (filter.key === 'date_year') {
          this.filtersValue['date_month'] = null
          this.filtersValue['date_f'] = null
        } else if (filter.key === 'date_f') {
          this.filtersValue['date_month'] = null
          this.filtersValue['date_year'] = null
        }
        //else if (filter.key === 'link_builder_f') {
        //this.filtersValue['date_month'] = null
        //this.filtersValue['date_year'] = null
        //this.filtersValue['date_f'] = null
        //}
        else if (key === filter.key) continue
      }

      this.$emit('updateTable', this.tableKey)
      this.visibleFilters = false
      this.updateTitleEndPart()
    },

    setVisibilityAdvancedPeriod(keys) {
      this.isVisibleAdvancedPeriod = !this.isVisibleAdvancedPeriod
      if (this.isVisibleAdvancedPeriod) this.hideFilters = []
      else this.hideFilters = keys
    },

    getTableLoyout() {
      if (this.isOpenModal) return 'auto'
      return this.columns.length > 5 ? 'fixed' : 'auto'
    },
    getColumnWidth(column, index) {
      if (!index) return column.width
      else if (this.isOpenModal) {
        return 'auto'
      }
      return this.columns.length > 5 ? column.width : ''
    },

    maximizeTable() {
      this.$emit('maximize', this.tableKey, !this.isMaximized)
    },

    updateTitleEndPart() {
      let titleEndPart = ''
      for (const key in this.filtersValue) {
        if (!this.filtersValue[key] || key === 'details_f') continue

        const filterMetadata = this.filtersMetaData.filter(
          (filter) => filter.key === key
        )

        if (filterMetadata) {
          filterMetadata.forEach((itemMeta) => {
            itemMeta.values.forEach((value) => {
              if (value.value === this.filtersValue[key]) {
                titleEndPart += `${value.label}, `
              }
            })
          })
        }
      }

      if (!titleEndPart) {
        if (this.filtersValue.date_month) {
          let YearMonthString = new Date(this.filtersValue.date_month)
            .toJSON()
            .slice(0, 7)
          titleEndPart = YearMonthString
        } else if (this.filtersValue.date_year) {
          titleEndPart = new Date(this.filtersValue.date_year)
            .toJSON()
            .slice(0, 4)
        }

        if (titleEndPart) titleEndPart += ', '
      }

      if (this.filtersValue.link_builder_f?.length) {
        titleEndPart += `${
          this.filtersValue.link_builder_f[0].slice(0, -9) + '...'
        } ${
          this.filtersValue.link_builder_f.length > 1
            ? `+${this.filtersValue.link_builder_f.length - 1}`
            : ''
        }`
        return (this.titleEndPart = titleEndPart)
      }
      if (titleEndPart.length && titleEndPart[titleEndPart.length - 2] === ',')
        titleEndPart = titleEndPart.slice(0, -2)

      this.titleEndPart = titleEndPart
    },
  },
}
</script>

<style lang="scss">
.el-month-table,
.el-year-table {
  td.current:not(.disabled) .cell {
    background-color: #fe8966;
    color: #ffffff;
  }

  td.today .cell {
    color: #fe8966;
  }

  td .cell:hover {
    color: #fe8966;
  }
}

.analytics-filters-select {
  .el-select__selected-item {
    max-width: 150px;
    .el-tag__content {
      margin-right: 0;
      .el-select__tags-text {
        text-overflow: initial;
      }
    }
    .el-tag__close {
      display: none !important;
    }
  }
  .el-tooltip__trigger {
    margin-right: 0;
    .el-tag,
    .el-select__tags-text {
      margin-right: 0;
    }
  }
  .el-select__tags-text {
    margin-right: 0;
  }
}

.analytics-filters-title {
  margin-top: 8px;
  padding-top: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer;
  color: #fe8966;
  border-top: 1px solid var(--el-border-color);
  font-size: 80%;
  // &.active {

  // }
}

.do-table-analytics {
  border-radius: 12px;

  .analytics-title-button {
    display: inline-block;
    cursor: pointer;
    margin-right: 5px;
    svg {
      fill: white;
      height: 19px;
      width: 19px;
    }
  }

  &.el-table thead.is-group {
    th.el-table__cell {
      background: #fe8966;
      color: white;
      .cell {
        line-height: 14px !important;
        white-space: nowrap;
      }
    }

    tr:first-of-type {
      th.el-table__cell {
        text-align: center;
        font-size: 120%;
      }
    }
  }

  &.el-table {
    .el-table__footer-wrapper tfoot td.el-table__cell {
      background-color: #f5f7fa;
      font-weight: bold !important;
    }
  }

  .analytics-title-cell {
    display: flex;
    justify-content: left;
    align-items: center;
    position: relative;

    div {
      white-space: nowrap;
    }

    .analytics-title-cell-button {
      // position: absolute;
      // right: 0;
      margin-left: 15px;
      color: white;
      padding: 0 8px;
      border-radius: 5px;
      cursor: pointer;
      border: 1px solid white;
      font-size: 70%;
      font-weight: normal;
      line-height: 18px;
      &:hover {
        background: rgba($color: #ffffff, $alpha: 0.8);
        color: #fe8966;
      }
    }
  }
}

.analytics-filters-wrapper {
  .analytics-filters-row {
    display: flex;
    align-items: center;
    padding: 5px 0;

    span {
      margin-right: 10px;
      font-weight: bold;
    }

    .custom-style.el-segmented {
      --el-segmented-item-selected-color: white;
      --el-segmented-item-selected-bg-color: #fe8966;
      --el-border-radius-base: 16px;
    }
  }
}
</style>
